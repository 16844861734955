import Autocomplete from './autocomplete'
import tw from 'twin.macro'

const Container = tw.div`
      flex 
      flex-col 
      items-center 
      justify-center 
      h-full 
      lg:mx-96 
      md:mx-24 
      mx-12
`

const Title = tw.a`
      text-6xl 
      font-medium 
      pb-40 
      text-yellow-500
`

const SearchPage = () => {
  return (
    <Container>
      <Title>Meerkt</Title>
      <Autocomplete />
    </Container>
  )
}

export default SearchPage
