import Head from 'next/head'
import SearchPage from '../components/searchpage'

const Index = () => {
  return (
    <>
      <Head>
        <title>Meerkt - Search Web</title>
      </Head>
      <SearchPage />
    </>
  )
}

export default Index
